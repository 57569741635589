import { DynamicImage, Seo, Text } from 'components';
import Header from 'components/Header';

import React from 'react';
import styled from 'styled-components';
import { mobile, useQuery } from 'styles/breakpoints';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useFirstVisitDate, usePageView, useQuizData } from 'utils/hooks';
import 'swiper/swiper.min.css';
import LoadingBar from './components/LoadingBar';
import { useRouter } from 'apis/history';
import { Quiz } from 'types/quiz';
import BlackFridayBanner from 'components/BlackFridayBanner';
import { AppState } from 'state/types';
import { useSelector } from 'react-redux';
import { normalizeStates } from 'utils/localization';

SwiperCore.use([Autoplay]);

const Finalizing = () => {
  const data = useQuizData('finalizing');
  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;
  const { countdownDiffInMS } = useFirstVisitDate();
  const { code, user, geolocation, quiz_answers } = useSelector(
    (state: AppState) => state.user,
  );
  const { goToEmail } = useRouter();

  const { isMobile } = useQuery();

  usePageView({
    client_code: code,
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    email: user?.email,
  });

  if (!data) {
    return null;
  }

  return (
    <>
      {data?.bfBanner ? (
        <BlackFridayBanner
          countdownDiffInMS={countdownDiffInMS}
          {...data.bfBanner}
        />
      ) : null}
      <Header logoVariant="center" sticky={false} color="newBaige" />
      <Container>
        {data.title && (
          <Title type={isMobile ? 'h1700' : 'h13Rem'}>{data.title}</Title>
        )}
        <StyledSwiper
          slidesPerView={1}
          noSwiping={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
            stopOnLastSlide: true,
          }}
        >
          {data.imageList.map((item: string, index: number) => (
            <StyledSwiperSlide key={index}>
              <SlideImage src={item.img} alt="" />
              <Text textAlign="center" type="body800" color="gray">
                {item?.text}
              </Text>
            </StyledSwiperSlide>
          ))}
        </StyledSwiper>
        <StyledLoadingBar
          onComplete={() => goToEmail()}
          duration={9}
          showPercents
        />
      </Container>
    </>
  );
};

export default Finalizing;

const Title = styled(Text).attrs({
  color: 'dark100',
})`
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.75rem;
  font-weight: 700;
`;

const StyledLoadingBar = styled(LoadingBar)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 100px;
  background-color: #f6f4ef;
  height: calc(100vh - 68px);
  @media ${mobile} {
    align-tems: center;
    padding-top: 0;
  }
`;

const StyledSwiper = styled(Swiper)`
  margin-top: 33px;
  width: 311px;
  min-height: 200px;
  pointer-events: none;
  margin-bottom: 16px;
`;
const StyledSwiperSlide = styled(SwiperSlide)`
  width: 311px;
  min-height: 200px;
  pointer-events: none;
`;

const SlideImage = styled(DynamicImage)`
  width: 225px;
  height: 200px;
  margin: auto;
  margin-bottom: 1.5rem;
`;
